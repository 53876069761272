// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
      apiKey: "AIzaSyARzS6pSJ5Iu3qyYMz623zPZDPDfi2hTjE",
  authDomain: "mapa-accesibilidad.firebaseapp.com",
  projectId: "mapa-accesibilidad",
  storageBucket: "mapa-accesibilidad.firebasestorage.app",
  messagingSenderId: "541030073305",
  appId: "1:541030073305:web:d307774c61617977538fc8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
