import React, { useState, useEffect } from "react";
import { db } from './firebase';
import { collection, addDoc, updateDoc, doc, deleteDoc, onSnapshot } from "firebase/firestore";
import { FaWheelchair, FaRoad, FaTimes } from 'react-icons/fa'; 
import { FaBridge, FaElevator } from 'react-icons/fa6'; 

import './App.css';

function App() {
  const [mapImage] = useState("/images/mapa.jpg");
  const [points, setPoints] = useState([]);
  const [selectedPointType, setSelectedPointType] = useState(null);
  const [zoomLevel] = useState(1);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [newMessage, setNewMessage] = useState("");

  // Listener en tiempo real para sincronizar puntos
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "mapPoints"), (snapshot) => {
      const pointsData = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setPoints(pointsData);
    });

    // Limpia el listener al desmontar el componente
    return () => unsubscribe();
  }, []);

  const addPoint = async (x, y) => {
    if (!selectedPointType) return;

    const newPoint = {
      x,
      y,
      tipo: selectedPointType,
      mensaje: "",
    };

    await addDoc(collection(db, "mapPoints"), newPoint);
    setSelectedPointType(null);
  };

  const updatePointMessage = async (pointId, message) => {
    const pointRef = doc(db, "mapPoints", pointId);
    await updateDoc(pointRef, { mensaje: message });
    setSelectedPoint(null);
    setNewMessage("");
  };

  const deletePoint = async (pointId) => {
    const pointRef = doc(db, "mapPoints", pointId);
    await deleteDoc(pointRef);
    setSelectedPoint(null);
  };

  const handleMapClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    const adjustedX = offsetX / zoomLevel;
    const adjustedY = offsetY / zoomLevel;
    
    if (selectedPointType) {
      addPoint(adjustedX, adjustedY);
    }
  };

  const handlePointClick = (point) => {
    setSelectedPoint(point);
    setNewMessage(point.mensaje || "");
  };

  return (
    <div className={`${isDarkMode ? "bg-gray-900 text-white" : "bg-white text-black"} h-screen`}>
      <div className="flex justify-between items-center p-4">
        <h1 className="text-2xl font-bold">Mapa de Accesibilidad Urbana</h1>
        <button
          onClick={() => setIsDarkMode(!isDarkMode)}
          className="p-2 border rounded bg-gray-700 text-white"
        >
          {isDarkMode ? "Modo Claro" : "Modo Oscuro"}
        </button>
      </div>

      {/* Contenedor de íconos de puntos dentro del mapa */}
      <div className="relative flex space-x-4 p-4 z-10">
        <div
          onClick={() => setSelectedPointType("Elevador")}
          className={`p-3 rounded cursor-pointer flex items-center justify-center 
            ${selectedPointType === "Elevador" ? "bg-blue-700 border-2 border-blue-300" : "bg-blue-500"}`}
        >
          <FaElevator />
          <div className="tooltip">Elevador</div>
        </div>
        <div
          onClick={() => setSelectedPointType("Puente")}
          className={`p-3 rounded cursor-pointer flex items-center justify-center 
            ${selectedPointType === "Puente" ? "bg-green-700 border-2 border-green-300" : "bg-green-500"}`}
        >
          <FaBridge />
          <div className="tooltip">Puente</div>
        </div>
        <div
          onClick={() => setSelectedPointType("Rampa")}
          className={`p-3 rounded cursor-pointer flex items-center justify-center 
            ${selectedPointType === "Rampa" ? "bg-yellow-700 border-2 border-yellow-300" : "bg-yellow-500"}`}
        >
          <FaWheelchair />
          <div className="tooltip">Rampa</div>
        </div>
        <div
          onClick={() => setSelectedPointType("Banqueta")}
          className={`p-3 rounded cursor-pointer flex items-center justify-center 
            ${selectedPointType === "Banqueta" ? "bg-red-700 border-2 border-red-300" : "bg-red-500"}`}
        >
          <FaRoad />
          <div className="tooltip">Banqueta</div>
        </div>
      </div>

      {/* Área del mapa donde los puntos pueden ser añadidos */}
      <div
        className="relative bg-gray-200 border overflow-hidden h-[100vh] mb-12"
        onClick={handleMapClick}
      >
        <div
          className="relative w-full h-full max-w-full max-h-full mb-4"
          style={{
            backgroundImage: `url(${mapImage})`,
            backgroundSize: `${zoomLevel * 100}%`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        >
          {points.map((point) => (
            <div
              key={point.id}
              className="absolute"
              style={{
                left: `${point.x * zoomLevel}px`,
                top: `${point.y * zoomLevel}px`,
              }}
              onClick={() => handlePointClick(point)}
            >
              <div
                className={`w-6 h-6 rounded-full ${point.tipo === "Elevador" ? "bg-blue-500" : 
                  point.tipo === "Puente" ? "bg-green-500" : 
                  point.tipo === "Rampa" ? "bg-yellow-500" : 
                  "bg-red-500"} flex items-center justify-center relative`}
              >
                <div className="tooltip">
                  <div className="p-2 w-40 max-w-xs break-words">{point.mensaje || point.tipo}</div>
                </div>
                {point.tipo === "Elevador" && <FaElevator />}
                {point.tipo === "Puente" && <FaBridge />}
                {point.tipo === "Rampa" && <FaWheelchair />}
                {point.tipo === "Banqueta" && <FaRoad />}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Formulario para agregar o editar un mensaje */}
      {selectedPoint && (
        <div className="fixed bottom-10 left-1/2 transform -translate-x-1/2 p-4 bg-white shadow-lg rounded-lg w-72 z-20">
          <h2 className="text-xl text-black mb-4">Punto Seleccionado: {selectedPoint.tipo}</h2>
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="w-full h-16 p-2 border rounded mb-4 text-black"
            placeholder="Escribe tu mensaje aquí..."
          />
          <button
            onClick={() => updatePointMessage(selectedPoint.id, newMessage)}
            className="w-full bg-blue-500 text-white py-2 rounded mb-2"
          >
            Guardar Mensaje
          </button>
          <button
            onClick={() => deletePoint(selectedPoint.id)}
            className="w-full bg-red-500 text-white py-2 rounded mb-2"
          >
            Eliminar Punto
          </button>
          <button
            onClick={() => setSelectedPoint(null)}
            className="absolute top-2 right-2 p-1 bg-gray-500 text-white rounded-full"
          >
            <FaTimes />
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
